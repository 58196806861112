import { environment } from '@src/environments/environment';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { provideRouter, Routes, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideState, provideStore } from '@ngrx/store';
import {
  registrationListFeatureKey,
  registrationListReducer
} from '@registrations/registration-list/state/registration-list.reducer';
import { initialRequestListState, listReducer } from '@fines/list/state/list.reducer';
import { RegistrationService } from '@shared/services/registration/registration.service';
import { initialScanListState, scanListReducer, scanListStateFeatureKey } from '@scans/scan-list/state/scan-list.reducer';
import { CoreModule } from '@core/core.module';
import { provideStoreDevtools } from '@ngrx/store-devtools';

if (environment.production) {
  enableProdMode();
}

const fmsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'registrations'
  },
  {
    path: 'registrations',
    providers: [provideState(registrationListFeatureKey, registrationListReducer)],
    children: [
      {
        path: '',
        loadComponent: () => import('./app/registrations/registration-list/registration-list.component').then(component => component.RegistrationListComponent),
        providers: [provideState('requestList', listReducer, { initialState: initialRequestListState })]
      },
      {
        path: 'new',
        loadComponent: () => import('./app/registrations/registration-add-edit/registration-add-edit.component').then(component => component.RegistrationAddEditComponent)
      },
      {
        path: ':id/detail',
        loadComponent: () => import('./app/registrations/registration-detail/registration-detail.component').then(component => component.RegistrationDetailComponent),
        providers: [RegistrationService]
      },
      {
        path: ':id/edit',
        loadComponent: () => import('./app/registrations/registration-add-edit/registration-add-edit.component').then(component => component.RegistrationAddEditComponent)
      },
    ]
  },
  {
    path: 'fines',
    children: [
      {
        path: '',
        loadComponent: () => import('@app/fines/list/list.component').then(component => component.ListComponent),
        providers: [provideState('requestList', listReducer, { initialState: initialRequestListState })]
      },
      {
        path: ':id/answer',
        loadComponent: () => import('@app/fines/answer/answer.component').then(component => component.AnswerComponent)
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('@dashboard/dashboard.component').then(component => component.DashboardComponent)
  },
  {
    path: 'dashboard-v2',
    loadComponent: () => import('./app/dashboard-v2/dashboard.component').then(component => component.DashboardComponent)
  },
  {
    path: 'scanning',
    children: [
      {
        path: '',
        loadComponent: () => import('@scans/scan-list/scan-list.component').then(component => component.ScanListComponent),
        providers: [provideState(scanListStateFeatureKey, scanListReducer, { initialState: initialScanListState })],
      },
      {
        path: 'new',
        loadComponent: () => import('@scans/scan-add/scan-add.component').then(component => component.ScanAddComponent)
      },
      {
        path: ':id/detail',
        loadComponent: () => import('@scans/scan-detail/scan-detail.component').then(component => component.ScanDetailComponent)
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'registrations'
  }
];

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      fmsRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      })
    ),
    provideStore(),
    provideStoreDevtools({ maxAge: 25,
      logOnly: environment.production
      , connectInZone: true }),
    importProvidersFrom(
      CoreModule
    )
  ]
})
  .catch(err => console.error(err));
